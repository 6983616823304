import React from 'react';
import gpt3Logo from '../../assets/logo.png';
import './footer.css';
import WppIcon from '../../assets/wppicon.png'

const Footer = () => (
  <div id="footer" className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__light">Contactate con uno de nuestros asesores</h1>
    </div>

    <a href="https://wa.me/5491125071506?text=Hola!%20Quiero%20hablar%20con%20un%20asesor" target="_blank">
    <div className="gpt3__footer-btn whatsapp-buttonn">
      <p>Dejanos tu mensaje</p>
        <img src={WppIcon} alt="WhatsApp Icon" />
    </div>
      </a>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Navegacion</h4>
        <a href='#home'> <p>Inicio</p></a>
        <a href="#wgpt3"> <p>Nosotros</p></a>
        <a href="#features"> <p>Servicios</p></a>
        <a href="#blog"><p>Instalaciones</p></a>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Redes</h4>
        <p>Instagram </p>
        <p>Facebook </p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Ponte en contacto</h4>
        <p>+54 9 1135072413</p>
        <p>info@lionseg.com</p>
        <p>jorge@lionseg.com</p>

      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 LionSeg. Desarrollo por <a href="https://flipwebco.com/" className="flipweb">@FlipWebco</a>.</p>
    </div>
  </div>
);

export default Footer;
