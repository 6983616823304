import React from 'react';
import { tarea, cetec, cotea, romicar, xtreme } from './imports';
import { motion } from 'framer-motion';
import { fadeIn } from '../../variants'
import './brand.css';

const Brand = () => (
  <motion.div 
  
  className="gpt3__brand section__padding">
    <div>
      <img  src={tarea} />
    </div>
    <div>
      <img src={cetec} />
    </div>
    <div>
      <img src={cotea} />
    </div>
    <div>
      <img src={romicar} />
    </div>
     <div>
      <img src={xtreme} />
    </div> 
  </motion.div>
);

export default Brand;
