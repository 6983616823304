import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';
import { motion } from 'framer-motion';
import { fadeIn } from '../../variants'



const Blog = () => (
  <div className="gradient__bg2 gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Instalacion de sistemas de seguridad.</h1>
    </div>
    <div className="gpt3__blog-container">
      <motion.div
      //  variants={fadeIn('up', 0.1)}
      //  initial="hidden"
      //  whileInView={'show'}
      //  viewport={{ once: false, amount: 0.7 }}
       className="gpt3__blog-container_groupB"
      //  transition={{ duration: 0.5 }} 
       >
      <Article imgUrl={blog01} date="Proporcionamos sistemas de cámaras de seguridad para hogares, tanto en su perímetro como en espacios comunes, garantizando una protección integral para su tranquilidad y la de su familia." text="Instalacion en hogares y comercios" />
      <Article imgUrl={blog02} date="Tenga el control total de su empresa: supervisión de operarios, máquinas y procesos." text="Instalacion en negocios y empresas" />
      <Article imgUrl={blog03} date="Ofrecemos sistemas de cámaras de seguridad para perimetros y espacios comunes, tales como calles y accesos, entre otros." text="Intalacion en barrios cerrados " />
      </motion.div>
    </div>
  </div>
);

export default Blog;
