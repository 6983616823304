import React from 'react';
import { motion } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation'; 
import camera from '../../assets/headerimg.png';
import './header.css';
import { fadeIn } from '../../variants'



const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <motion.h1 
      variants={fadeIn('up',0.1)} 
      initial="hidden"
      whileInView={'show'} 
      transition={{ duration: 0.4 }} 
      className="gradient__text">  
        Seguridad que nunca duerme. Su tranquilidad, <br/>
      <TypeAnimation sequence={[
            ' nuestra promesa', 
            3000,
            ' nuestro compromiso',
            3000,
          ]}
          speed={10}
          wrapper='span'
          repeat={Infinity}
          />
          </motion.h1>
          <motion.p 
          variants={fadeIn('up',0.2)} 
          initial="hidden" whileInView={'show'}
          viewport={{once: false, amount: 0.7}} 
          transition={{ duration: 0.5 }} 
           >
Ofreciendo seguridad constante y sofisticada. Impulsados por la experiencia, la confiabilidad y la vanguardia tecnológica, elevamos el estándar de protección a su máxima expresión para usted.          </motion.p>

      <motion.div  
      variants={fadeIn('up',0.1)}
       initial="hidden" whileInView={'show'} 
       viewport={{once: false, amount: 0.7}}  
       className="gpt3__header-content__input">
      <a href="#wgpt3">  <button type="button">Conoce mas</button> </a>
      </motion.div>

    </div>

    <motion.div  variants={fadeIn('down',0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.3}} className="gpt3__header-image">
      <img src={camera} />
    </motion.div>
  </div>
);

export default Header;
